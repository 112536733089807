import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../../context/AuthContext";
import { TControlContext } from "../../hooks/useTControl";
import { getDynamicValuesSmena } from "../../../../http/http";
import { ModelTable } from "../../../../../../components/ModelTable/ModelTable";
import { TControlSelect } from "../../../../utils/TControlSelect/TControlSelect";

export let OperParam = () => {
  const { authTokens } = useContext(AuthContext);
  let { memoizedBoilersHouses, memoizedSmena } = useContext(TControlContext);

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    return dateObj.toLocaleString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const [dynamicValuesGet, setDynamicValuesGet] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);

  // Загружаем данные для смены один раз при изменении активной смены
  useEffect(() => {
    getDynamicValuesSmena(authTokens, memoizedSmena?.activeSmena?.[0]?.pk, setDynamicValuesGet);
  }, [authTokens, memoizedSmena?.activeSmena]);

  // Фильтрация записей для выбранной котельной
  useEffect(() => {
    const selectedBoilerhouseId = memoizedBoilersHouses?.selecterBoilersHouse;
    if (selectedBoilerhouseId) {
      const boilerhouseValues = dynamicValuesGet.filter(
        (item) => item.boilerhouse_id === selectedBoilerhouseId
      );
      setFilteredValues(boilerhouseValues);
    }
  }, [dynamicValuesGet, memoizedBoilersHouses?.selecterBoilersHouse]);

  // Применение алгоритма утечки к отфильтрованным данным
  useEffect(() => {
    if (filteredValues.length <= 1) {
      // Если только одна запись, удаляем предупреждения
      setFilteredValues((prev) =>
        prev.map((item) => ({ ...item, warning: null }))
      );
      return;
    }

    const updatedValues = filteredValues.map((item, index) => {
      if (index === 0) {
        // Первая запись никогда не помечается утечкой
        return { ...item, warning: null };
      }

      const previousItem = filteredValues[index - 1];
      const currentPressure = item.pressure || 0; // Считаем пустые значения как 0
      const previousPressure = previousItem.pressure || 0; // Считаем пустые значения как 0

      const hasPressureDrop = checkPressureDrop(currentPressure, previousPressure);

      return {
        ...item,
        warning: hasPressureDrop ? "Внимание утечка!" : null,
      };
    });

    setFilteredValues(updatedValues);
  }, [filteredValues]);

  const checkPressureDrop = (current, previous) => {
    return previous && (current < previous * 0.9 || current > previous * 1.1);
  };

  return (
    <div className="container">
      <TControlSelect
        name={"Выберите котельную"}
        list={memoizedBoilersHouses?.boilersHouses}
        value={'pk'}
        visible_value={'address'}
        getter={memoizedBoilersHouses?.selecterBoilersHouse}
        setter={memoizedBoilersHouses?.setSelecterBoilersHouse}
        parse={'int'}
        defaultValue={memoizedBoilersHouses?.selecterBoilersHouse}
      />
      {filteredValues.length !== 0 ? (
        <>
          {/* Таблицы и логика рендера остаются неизменными */}
          <ModelTable>
            <thead>
              <tr>
                <th colSpan="13">Параметры контроля</th>
              </tr>
              <tr>
                <th rowSpan="2">Время/Дата</th>
                <th rowSpan="2">Котельная</th>
                <th>Давление воды на вводе</th>
                <th colSpan="4">Теплоноситель</th>
                <th colSpan="4">ГВС</th>
                <th colSpan="1">Давление газа на вводе</th>
                <th rowSpan="2">Наличие утечек</th>
              </tr>
              <tr>
                <th>Pвх</th>
                <th>T1</th>
                <th>P1</th>
                <th>T2</th>
                <th>P2</th>
                <th>T3</th>
                <th>P3</th>
                <th>T4</th>
                <th>P4</th>
                <th>Pгаз</th>
              </tr>
            </thead>
            <tbody>
              {filteredValues.map((item) => (
                <tr key={item.id}>
                  <td>{formatDate(item.created_at)}</td>
                  <td>{item.boilerhouse_id}</td>
                  <td>{item.pressure}</td>
                  <td>{item.t1}</td>
                  <td>{item.p1}</td>
                  <td>{item.t2}</td>
                  <td>{item.p2}</td>
                  <td>{item.t3}</td>
                  <td>{item.p3}</td>
                  <td>{item.t4}</td>
                  <td>{item.p4}</td>
                  <td>{item.gas}</td>
                  <td>
                    {item.warning && <div style={{ color: "red" }}>{item.warning}</div>}
                  </td>
                </tr>
              ))}
            </tbody>
          </ModelTable>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
