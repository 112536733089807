import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { getJobTitle } from "./http/http.jsx";
import AuthContext from "../../context/AuthContext";

import { AddHouse } from "./components/StartPage/AddHouse/AddHouse.jsx";
import { AddWear } from "./components/StartPage/AddWear/AddWear.jsx";
import AddressSearch from "./components/StartPage/SearchHouse/AddressSearch.jsx";

import "./WearApp.css";

export const WearApp = () => {
  let navigate = useNavigate();
  let { authTokens, logoutUser } = useContext(AuthContext); // Токен доступа
  let [jobTitle, setJobTitle] = useState(); // Должность
  let [selectedComponent, setSelectedComponent] = useState(null); 

  useEffect(() => {  //Авторизация по должности
    getJobTitle(authTokens).then((data) => {
      if (!data?.[0]?.wear_access) {
        navigate("/", { replace: true });
      }
      if (data?.[0]?.job_title) {
        setJobTitle(data[0].job_title);
      } else if (data?.detail === "Учетные данные не были предоставлены.") {
        logoutUser();
      } else {
        localStorage.setItem("last_page", "/services/wearbuilding");
        navigate("/", { replace: true });
      }
    });
  }, []);

  //Установка состояния в SearchAddress
  const handleAddressSelected = () => {
    setSelectedComponent(3); // Устанавливаем выбранный компонент на AddWear
  };

  //Отрисовка нужного компонента
  const renderComponent = () => {
    if (selectedComponent === 1) {
      return <AddHouse />;
    } else if (selectedComponent === 2) {
      return <AddressSearch onAddressSelected={handleAddressSelected} />; // Передаем функцию
    } else if (selectedComponent === 3) {
      return <AddWear />;
    }
    return null;
  };

  return (
    <Layout>
      <div className="Wear_App_Main">
        {!selectedComponent && (
          <>
          <h1 className="Wear_App_Main_h1">Расчет износа зданий</h1>
          <div className="Wear_App_Main_button_group">
            <button 
              className="Wear_App_Main_button" 
              onClick={() => setSelectedComponent(1)}
              >
              Добавить здание
            </button>
            <button 
              className="Wear_App_Main_button" 
              onClick={() => setSelectedComponent(2)}
            >
              Добавить износ
            </button>
          </div>
          </>
        )}
        
        {selectedComponent && (
          <>
            {renderComponent()}
          </>
        )}
      </div>
    </Layout>
  );
};
